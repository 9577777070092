/**
 * @function dropdown
 * @param {string} selector id of the select with the options to load
 * @param {number} max max number of options to show if not set or < 0 then it show them all, default -1
 * @returns 
 */
function dropdown(selector, max = -1) { 
    return {
        options: [],
        optionsToShow: [],
        selected: [],
        maxOptions: max,
        show: false,
        open() { this.show = true },
        close() { this.show = false },
        isOpen() { return this.show === true },
        select(index, _event) {
            if (!this.options[index].selected) {
                this.options[index].selected = true;
                this.options[index].element.selected = true;
                this.selected.push(index);
            } else {
                this.selected.splice(this.selected.lastIndexOf(index), 1);
                this.options[index].selected = false;
                this.options[index].element.selected = false;
            }
        },
        removeOption(index, option) {
            this.options[option].selected = false;
            this.options[option].element.selected = false;
            this.selected.splice(index, 1);
        },
        loadOptions() {
            const options = document.getElementById(selector).options;
            for (let i = 0; i < options.length; i++) {
                const selected = options[i].getAttribute('selected') != null ? options[i].getAttribute('selected') : false;
                this.options.push({
                    value: options[i].value,
                    text: options[i].innerText,
                    element: options[i],
                    index: i,
                    selected
                });
                if (selected) {
                    this.selected.push(i);
                }
            }
            this.loadOptionsToShow(this.options);
        },
        loadOptionsToShow(options) {
            this.optionsToShow = this.maxOptions > 0 ? options.slice(0, this.maxOptions) : options;
        },
        selectedOptions(){
            return this.selected.map((option)=>{
                return [this.options[option].value];
            })
        },
        filterQuery(event) {
            const toQuery = event.target.value;
            if (toQuery.length > 2) {
                this.optionsToShow = this.options.filter(option => option.text.toLowerCase().includes(toQuery.toLowerCase()));
                this.open();
                return;
            }
            if (toQuery.length === 0) {
                this.loadOptionsToShow(this.options)
                this.open();
                return;
            }
        }
    }
}

window.dropdown = dropdown;
