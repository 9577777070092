$(function() {

  // This polyfill for function.prototype.bind (which phantomjs doesn't have) makes cucumber tests work in Vagrant.
  // Adapted from Mozilla Developer Network example at https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Function/bind
  if (typeof Function.prototype.bind !== 'function') {
    Function.prototype.bind = function bind(obj) {
      var args = [].slice.call(arguments, 1),
          self = this,
          nop = function() {},
          bound = function() {
            return self.apply(this instanceof nop ? this : (obj || {}), args.concat([].slice.call(arguments)));
          };
      nop.prototype = this.prototype || {}; // Firefox cries sometimes if prototype is undefined
      bound.prototype = new nop();
      return bound;
    };
  }

  // navigation#index
  $("#navigation_link_wrapper tr select").change(function(){
     var $container = $(this).parent().parent();
     var $cat_id = $container.find("input.category_id");
     var $text = $container.find("input.text_field");
     var $url = $container.find("input.url_field").hide();
     var $selector = $container.find("td.link div").hide();
     var type = $(this).val();

     if (type === "custom") {
       $url.show();
     } else if (type === "category"){
       $selector.show();
       // only load the selectors once,  also maintain state.
       if ($selector.find("select").length === 0){
         $("#category_selects .root_cat")
           .clone()
           .prependTo($selector)
           .change(function(){
             $text.val($(this).find("option:selected").text());
             $cat_id.val($(this).val());

             var $sub = $("#category_selects").find("#cat_"+$(this).val()).clone();
             $sub.change(function(){
               $text.val($(this).find("option:selected").text());
               $cat_id.val($(this).val());
             })
             $container.find("td.optional div").html($sub);
           })
       } else {
         /* renable state */
         if ($selector.find("select:last").val() === "none")
           $selector.find("select:first").change();
         else
           $selector.find("select:last").change();
       }
     } else if (type in navigationLinks) {
       $text.val(navigationLinks[type]["text"]);
     }
   });

   $("#current_links td.type").each(function(){
     var $td = $(this);
     if ($td.find("select option:selected").val() === "custom"){
       $td.parent().find(".url_field").show();
     }
   });

   $('.export_button').on('click', function(e) {
     var button = $(e.target);
     var form = button.closest('form');
     form.attr('action', button.data('url'));
     form.attr('method', button.data('method'));
     form.submit();
   });

   $('input#toggle-all-checkbox').on('click', function (e) {
     var val = e.target.checked;
     var form = $(e.target).closest('form');
     form.find('input[type="checkbox"][name^="publisher"]').prop('checked', val);
   });
});
