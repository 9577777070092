import JSONEditor from 'jsoneditorjs'

$(function() {
  var setError = function(errorMessage) {
    var errorLabel = $("#jsoneditor-errors");
    errorLabel.text(errorMessage);
    errorLabel.removeClass("custom-data-editor-errors-invisible");
  }

  var clearErrors = function() {
    var errorLabel = $("#jsoneditor-errors");
    errorLabel.text("");
    errorLabel.addClass("custom-data-editor-errors-invisible");
  }

  var container = document.getElementById("jsoneditor");

  if (!!container) {
    // create the editor
    var options = {
      mode: "code",
      indentation: 4,
      search: false,
      onChange: function() {
        try {
          clearErrors();
          var customData = JSON.stringify(editor.get());
          $("#ad_custom_data").val(customData);
        } catch(error) {
          setError("There are errors in the JSON blob.");
        }
      },
    };
    var editor = new JSONEditor(container, options);
    var value = $("#ad_custom_data").val() || "{}";
    var initialJson = JSON.parse(value);

    editor.set(initialJson);
  }
});
